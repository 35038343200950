import React from "react"
import Emoji, { EmojiAnimator } from "components/Emoji"
import css from "./style.module.scss"
import Flex from "components/Flex"
import cn from "classnames"
import { Link } from "gatsby"
const words = [
  "Javascript",
  "Milan, Italy",
  "Domotics",
  "Pastel colors",
  "Drawing",
  "Read",
  "Frisbee",
  "Tea",
  "Emoji",
]

const Word = ({ word }) => {
  const onClick = () => {
    window.metrical.trackEvent("click_word", { word })
  }
  return (
    <div className={css.word} onClick={onClick}>
      {word}
    </div>
  )
}

export const Title = ({ small, back }) => (
  <div className={cn(css.title, small && css.small)}>
    <Flex>
      <div>
        {back && <Link to={back}>Back</Link>}
        <h1>
          <Link to="/">{process.env.GATSBY_TITLE}</Link>
        </h1>
        <h3>Full-Stack developer & UI/UX designer.</h3>
        {/* <p>
          I will just write some words here that could be useful for the reader:
          <br />
        </p>
        <div className={css.words}>
          {words.map(w => (
            <Word key={w} word={w} />
          ))}
        </div> */}
      </div>
    </Flex>
  </div>
)

export default Title
