import React from "react"
import Title from "components/Title"
import css from "./style.module.scss"
import { PROJECTS, SOCIAL } from "utils/config"
const ProjectItem = ({ title, url, description, image }) => {
  return (
    <a
      target="_blank"
      href={url ? "https://" + url : ""}
      className={css.project}
    >
      <div className={css.image}>
        <img src={"/" + image} />
      </div>
      <div className={css.content}>
        <h3>{title}</h3>
        <p>{description}</p>
        <small>{url && url.split("?ref=")[0]}</small>
      </div>
    </a>
  )
}
export default function Sidebar() {
  return (
    <div className={css.sidebar}>
      <h2 className={css.subTitle}>Latest Projects:</h2>
      <div className={css.projects}>
        {PROJECTS.filter(p => p.url)
          .slice(0, 4)
          .map(p => {
            return <ProjectItem {...p} key={p.title} />
          })}
      </div>
    </div>
  )
}
