import React from "react"
import { Section } from "components/SectionList"
import css from "./style.module.scss"
import { Link } from "gatsby"
import cn from "classnames"
export const PostLink = ({ post }) => (
  <Link className={css.post} to={post.fields.slug}>
    <h3>{post.frontmatter.title}</h3>
    <p>{post.excerpt}</p>
    <small>{post.frontmatter.date}</small>
  </Link>
)

export const Articles = ({ edges, long }) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return <div className={cn(css.articles, long && css.long)}>{Posts}</div>
}

const Blog = ({ postsData }) => {
  const Posts = postsData
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Section title="Blog">
      <p className={css.description}>Here are my latest articles:</p>
      <Articles edges={postsData} />
      <Link to="/blog">View all articles</Link>
    </Section>
  )
}
export default Blog
