import React from "react"
import css from "./style.module.scss"

const Item = ({ title, url, description, image }) => {
  return (
    <a target="_blank" href={url ? "https://" + url : ""} className={css.item}>
      <div className={css.image}>
        <img src={image} />
      </div>
      <div className={css.content}>
        <h3>{title}</h3>
        <p>{description}</p>
        <small>{url && url.split("?ref=")[0]}</small>
      </div>
    </a>
  )
}

export const Section = ({ title, children }) => (
  <div className={css.section}>
    <div className={css.title}>{title}</div>
    {children}
  </div>
)

const SectionList = ({ data, title }) => (
  <Section title={title}>
    {data.map(p => (
      <Item {...p} key={p.title} />
    ))}
  </Section>
)

export default SectionList
