export const SOCIAL = [
  {
    title: "Twitter",
    url: "https://twitter.com/urcoilbisurco",
  },
  {
    title: "Medium",
    url: "https://medium.com/@marassi",
  },
  {
    title: "Github",
    url: "https://github.com/urcoilbisurco",
  },
  {
    title: "Instagram",
    url: "https://instagram.com/francesco_marassi",
  },
  {
    title: "Contact Me",
    url: "mailto:marassi.francesco@gmail.com",
  },
  {
    title: "My Resume",
    url: "/CV_Francesco Marassi.pdf",
  },
]

export const PROJECTS = [
  {
    title: "Stori",
    url: "stori.social?ref=francesco.space",
    description: "Backup and analyze your Instagram Stories like a boss.",
    image: "stori.logo.png",
  },
  // {
  //   title: "Trackthisforme",
  //   url: "trackthisfor.me?ref=francesco.space",
  //   description:
  //     "Web & Android app for custom tracking. For Quantified self nerds.",
  //   image: "trackthisforme.logo.png",
  // },
  {
    title: "Metrical",
    url: "metrical.xyz?ref=francesco.space",
    description:
      "A web analytics tool that is easy to use, privacy oriented and where you don't have to have a marketing degree to know how to use it.",
    image: "metrical.logo.png",
  },
  {
    title: "Cozy Reader",
    url: "cozyreader.xyz",
    description: "Blockstack-backed and privacy-focus read it later service",
    image: "cozy.logo.png",
  },
  {
    title: "Time Progress",
    url: "time-progress.francesco.space?ref=francesco.space",
    description:
      "Time Progress shows you the percentage of the current year, month, week and day in the new tab page.",
    image: "timeprogress.logo.png",
  },
]

export const EXPERIMENTS = [
  {
    title: "Feminipsum",
    url: "feminipsum.space?ref=francesco.space",
    description: "A Lorem Ipsum Generator for equality.",
    image: "feminipsum.logo.png",
  },
  {
    title: "Atoms",
    url: "useatoms.xyz",
    description: "An habit tracker that doesn't track you back.",
    image: "atoms.logo.png",
  },
  {
    title: "Aurora Smart Home",
    url:
      "medium.com/@marassi/control-your-home-using-only-javascript-72a3b071c894",
    description:
      "How to use React, Node.js and Espruino on ESP8266 to build a smart home.",
    image: "aurora.logo.png",
  },
  {
    title: "Listen",
    url: "listen.francesco.space?ref=francesco.space",
    description: "Show the world what you are currently listening to.",
    image: "listen.logo.png",
  },
]
