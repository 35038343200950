import React from "react"
import Header from "components/Header"
import css from "./style.module.scss"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"
import "style/reset.scss"
const data = {
  title: "Francesco Marassi",
  description: "Full-Stack developer & UI/UX designer.",
}

export const Wrapper = ({ children }) => (
  <div className={css.wrapper}>{children}</div>
)

export const Main = ({ children }) => <div className={css.main}>{children}</div>

export const Side = ({ children }) => <div className={css.side}>{children}</div>

const Head = () => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>Francesco Marassi</title>
    <meta name="title" content={data.title} />
    <meta name="description" content={data.description} />
    <meta
      name="google-site-verification"
      content="jjl6-R4nZHYMtVT_jkjQ6WUmbXzqdVFGlJLThwRPZUA"
    />
    <meta name="monetization" content="$ilp.uphold.com/ZPAF9bE4AH86" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={process.env.GATSBY_URL} />
    <meta property="og:title" content={data.title} />
    <meta property="og:description" content={data.description} />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={process.env.GATSBY_URL} />
    <meta property="twitter:title" content={data.title} />
    <meta property="twitter:description" content={data.description} />
    <link
      rel="alternate"
      type="application/rss+xml"
      title="Rss feed"
      href="https://francesco.space/rss.xml"
    />
    <link
      rel="apple-touch-icon"
      sizes="57x57"
      href={`/favicons/apple-icon-57x57.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="60x60"
      href={`/favicons/apple-icon-60x60.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href={`/favicons/apple-icon-72x72.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="76x76"
      href={`/favicons/apple-icon-76x76.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="114x114"
      href={`/favicons/apple-icon-114x114.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="120x120"
      href={`/favicons/apple-icon-120x120.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="144x144"
      href={`/favicons/apple-icon-144x144.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="152x152"
      href={`/favicons/apple-icon-152x152.png`}
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href={`/favicons/apple-icon-180x180.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="192x192"
      href={`/favicons/android-icon-192x192.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href={`/favicons/favicon-32x32.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="96x96"
      href={`/favicons/favicon-96x96.png`}
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href={`/favicons/favicon-16x16.png`}
    />
    <link rel="manifest" href={`/favicons/manifest.json`} />
    <meta name="msapplication-TileColor" content="#f7c0ab" />
    <meta name="theme-color" content="#f7c0ab" />
  </Helmet>
)

export const Page = ({ children }) => (
  <div className={css.page}>
    <div className={css.container}>
      <Head />
      {children}
    </div>
  </div>
)

export default Page
